import '../App.css';
import React from 'react';
import Capacitation from '../Images/icons/4417115_mic_headset_person_support_operator.png';
import Implementation from '../Images/icons/4417096_line_command_root_laptop_access_prompt.png';
import HelpDesk from '../Images/icons/4417110_construction_builder_website_building site_crane_computer_pc.png'
import Titulo from './Components/Tittles'


export default class Services extends React.Component{
  render(){
  return (
    <>
    <div className="serv_Fondo alejadoTop">
    <div className="container container-details">
      <Titulo titulo="Servicios"/>
      <h4 className='blackLetter'>El soporte técnico de EFISeguros es continuo y en línea. Con nuestra herramienta que día a día evoluciona, su Bróker obtendrá más beneficios.</h4>
      <div className="row d-flex justify-content-evenly">
        <div className="Card col-6 rservices">
          <div className="face front">
            <img src={Implementation} class="card-img-top" alt="..."/>
            <h3 className="card-title Questions"><b>Implementacion</b></h3>
          </div>
          <div className="face back">
            <h3 className="card-title Questions"><b>Implementacion</b></h3>
            <p className="card-text">Servicio de instalación y puesta en funcionamiento del aplicativo, acompañado de una asesoría a nivel de hardware.</p>
          </div>
        </div>
        <div className="Card col-6 rservices">
        <div className="face front">
          <img src={Capacitation} class="card-img-top" alt="..."/>
          <h3 className="card-title Questions"><b>Capacitación</b></h3>
        </div>
        <div className="face back">
          <h3 className="card-title Questions"><b>Capacitación</b></h3>
          <p className="card-text">Cuando el Cliente EFI requiere de una capacitación, técnicos de la empresa están dispuestos a cumplir con este tipo de servicios.</p>
        </div>
        </div>
      </div>

      <div className="row d-flex justify-content-evenly">
          <div className="Card rservices">
            <div className="face front">
              <img src={HelpDesk} class="card-img-top" alt="..."/>
              <h3 className="card-title Questions"><b>Soporte Técnico</b></h3>
            </div>
            <div className="face back">
              <h3 className="card-title Questions"><b>Soporte Técnico</b></h3>
              <p className="card-text">A través de nuestra área de Service Desk, ofrecemos servicios de asesoría, soporte técnico referente a la operación.</p>
            </div>
          </div>
      </div>
      </div>
      </div>
    </>
  );
  }
}