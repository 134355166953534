import '../App.css';
export default function Home(){
    return(
        <>
            <div className="container-portada">
                <div className="capa-gradient"></div>
                    <div className="container-details">
                            <strong>
                                <h1 className="details"><strong>TEFISOFT CIA. LTDA</strong></h1>
                                <div className="glassmorfism">
                                    Forme parte de nuestros selectos clientes satisfechos.
                                </div>
                            </strong>
                    </div>
            </div>
        </>
    );
}