import React, { Component } from 'react'
import Construction from '../../Images/icons/4417110_construction_builder_website_building site_crane_computer_pc.png'

export default class Facturacion extends Component {
  render() {
    return (
      <>
      <div className="fondo">
        <div className='row row-cols-1 row-cols-lg-2 g-2 g-lg-2'>
          <div className="col text-center">
            <img src={Construction} className="imageConstruction" alt=""/>
          </div>
          <div className="col">
            <div className="centradoVertical">
              <h1 className='c'><strong>Próximamente!</strong></h1>
              <h5 className='c'>Nos encontramos en proceso de desarrollo y mejoras para brindar un servicio completo.</h5>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}
