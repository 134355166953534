import React from "react";
import "aos/dist/aos.css";
import Imagen from '../../Images/Caratula.png';
import Titulo from '../Components/Tittles';

const InfoCover = () => {
    return(
        <>
            <div className="container text-center">
                <Titulo titulo="¿Quienes somos?"/>
                <div data-aos="fade-left">
                    <img src={Imagen} className="w-50 h-100vh Caratula" alt='Imagen No encontrada'/>
                </div>
            </div>
        </>
    );
};
export default InfoCover;