import React from 'react';

const Squares = (props) => {
  return (
    <>
      <div className="col">
        <div className={"cuadros whiteLetter " + props.color}>
          <div className="card2 border">
            <div className="face front">
              <h4>{ props.txt }</h4>
            </div>
            <div className="face back">
              <p>{ props.info }</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Squares;