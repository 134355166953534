import './App.css';
import Home from './Pages/Home';
import NavBar from './Pages/Components/NavBar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import EfiSeguros from './Pages/Products/EfiSeguros';
import Facturacion from './Pages/Products/Facturacion';
import Information from './Pages/Information';
import Services from './Pages/Services';
import Footer from './Pages/Components/Footer';
function App() {
  return (
    <>
    <div className="bg_animated">
      <BrowserRouter>
        <NavBar/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/Information" element={<Information/>}/>
          <Route path="/Services" element={<Services/>}/>
          <Route path="/EfiSeguros" element={<EfiSeguros/>}/>
          <Route path="/Facturacion" element={<Facturacion/>}/>
        </Routes>
      </BrowserRouter>
    </div> 
    <Footer/>
    </>
  );
}

export default App;
