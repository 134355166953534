import '../App.css';
import React from 'react';
import DivInformation from './Components/DivInformation';
import InfoCover from './Components/InfoCover';
export default class Information extends React.Component{
    constructor(){
        super();
        this.state = {
            tarjetas: [
                {
                    id: "data1",
                    subtitle: "¿Quiénes somos?",
                    info: "Es una empresa de desarrollo de Software – con sede en Quito – Ecuador Con 19 años de experiencia en Desarrollo de Software para Brokers de Seguros."
                },
                {
                    id: "data2",
                    subtitle: "Misión",
                    info: "Continuar en el crecimiento como empresa de soluciones tecnológicas de alto valor agregado para los mercados de Seguros, Financiero, Industrial y Comercial, siempre con la firmeza de seguir trabajando ligados en nuestros valores morales y profesionales que nos caracterizan, lo que ha permitido encontrar la satisfacción de nuestros Clientes."
                },
                {
                    id: "data3",
                    subtitle: "Visión",
                    info: "Ser una empresa con presencia internacional en la industria del desarrollo de Software, inclinada al mercado de Seguros, Financiero, Industrial y Comercial, amparados en un equipo profesional y comprometido a largo plazo."
                }
            ]
        }
    }
    render(){
        return(
            <>
                <section className="info_parteUno alejadoTop">
                    <div className="contenedor-texto">
                        <InfoCover/>
                    </div>
                </section>
                <section className="info_parteDos">
                    <div className="contenedor-texto">
                        <div className="container">
                            <ul>
                                {this.state.tarjetas.map((tarjeta) => (
                                    <DivInformation 
                                    id={tarjeta.id}
                                    subtitle={tarjeta.subtitle}
                                    info={tarjeta.info}/>
                                ))}
                                <div data-aos="fade-up">
                                    <li className="mb-2 row">
                                                <button className="btnCollapse" data-bs-toggle="collapse" data-bs-target="#vm" aria-expanded="false" aria-controls="collapseExample">
                                                    <h1 className="Questions col-sm-5 float-start">Nuestros valores morales</h1>
                                                    <div className="col-sm-3 float-end">
                                                        <h1>+</h1>
                                                    </div>
                                                </button>
                                            </li>
                                    <hr/>
                                    <div className="collapse" id='vm'>
                                        <div className="card card-body cardBG">
                                        <p>Vocación de servicios Excelencia y profesionalismo.</p>
                                        <p>Excelencia y profesionalismo.</p>
                                        <p>Integridad y ética profesional.</p>
                                        <p>Confidencialidad y respeto por nuestros clientes.</p>
                                        <p>Compromiso con las tareas y orgullo por realizarlas.</p>
                                        <p>Innovación en todo lo que hacemos.</p>
                                        <p>Trabajo en equipo.</p>
                                        <p>Relaciones con proveedores y clientes basadas en el concepto “Ganar & Ganar”.</p>
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
