import React from "react";
import Aos from 'aos';
import { useEffect } from "react";
import "aos/dist/aos.css";

const Tittles = (props) => {
    useEffect(() => {
        Aos.init({duration: 5000});
    }, []);
    return(
        <>
            <div data-aos="fade-right">
                <h1 className="text-center Questions Titulo"><strong>{props.titulo}</strong></h1>
            </div>
        </>
    );
}
export default Tittles;