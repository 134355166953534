import React from 'react';
import Diagrama from '../../Images/EfiDiagramacion.jpg';
import Tittles from '../Components/Tittles';
import "aos/dist/aos.css";
import Cuadros from '../Components/Squares'
import Separacion from '../Components/Separation';

export default class EfiSeguros extends React.Component {
  constructor(){
    super();
    this.state = {
      CuadrosArriba: [
        {
          color:"primerColor",
          txt: "Parametrizable",
          info: "De acuerdo a las necesidades y politicas de la empresa puede parametrizar la información para obtener mejores resultados."
        },
        {
          color:"segundoColor",
          txt: "Cotizador de Seguros",
          info: "Mediante matrices y cuadros comparativos facilitará la toma de decisiones del cliente."
        },
        {
          color:"tercerColor",
          txt: "Producción",
          info: "A través de indicadores, correos y reportes puede verificar procesos pendientes que mantiene su Bróker. Registre su gestión comercial. Además, utilice todo tipo de documentos digitales."
        },
        {
          color:"cuartoColor",
          txt: "Cobranzas",
          info: "Ahorre su tiempo organizando un plan de ruta de cobranzas y pagos, EFISeguros se encargará de administrarlos."
        }
      ],
      CuadrosAbajo: [
        {
          color:"cuartoColor",
          txt: "Facturación",
          info: "No más facturación manual, de manera simple, fácil e inmediata genere sus facturas electronicamente."
        },
        {
          color:"tercerColor",
          txt: "Correspondencia y Comunicación",
          info: "A través de Rutas de Cartas, Remisiones y Mensajería puede tener el conocimiento del accionar de sus ejecutivos."
        },
        {
          color:"segundoColor",
          txt: "Reportes - Consultas",
          info: "Más de 500 reportes diferentes que permiten la toma de decisiones acertadas."
        },
        {
          color:"primerColor",
          txt: "Utilitarios Adicionales",
          info: "Emailing automáticos, recordatios, pendientes, consultas de pólizas, estados de siniestros, documentación personal, etc."
        }
      ]
    }

  }
  render(){
    return (
      <div className="container">
          <Tittles titulo="EFISeguros"/>
          <h4>
            Es un Software para Brokers, Intermediarias, Asesores de Seguros denominado “EFISeguros”, que permite Administrar, Controlar y Registrar la información generada en cada una de las instancias del proceso en un róker de Seguros.
          </h4>
          <img src={Diagrama} className="img-thumbnail rounded mx-auto w-70 d-block" alt="" />
          {/* <p>Video: https://www.youtube.com/watch?v=ER0gLvjJyco</p> */}
          <div className="mar-top3">
            <Separacion/>
            <div className="row row-cols-1 row-cols-lg-2 g-2 g-lg-2">
              <div className="col">
                <iframe width="100%" height="400" src="https://www.youtube.com/embed/ER0gLvjJyco" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="col">
                <div className="container">
                  <p className='infoVideo'>Contamos con una usabilidad cada vez mayor en las empresas que se dedican a vender y ofrecer seguros en el mercado nacional.</p>
                  <p className='infoVideo'>Diseñado para administrar seguros de varias sucursales o puntos de venta, con la información centralizada, de la cual puede obtener estadísticas parciales o totales.</p>
                  <p>La información se la registra de manera detallada y cuantificada, con el firme propósito de conseguir valores estadísticos para tomas de decisiones gerenciales, en brókeres pequeños, medianos y de alta escala.</p>
                </div>
              </div>
            </div>
          <Tittles titulo="Beneficios"/>
          <Separacion/>
          <div className="efiS_cuadros">
            <div className="text-center">
              <div className="row row-cols-2 row-cols-lg-4 g-lg-0 g-0">
                {
                  this.state.CuadrosArriba.map((cuadroder) => (
                    <Cuadros
                    color={cuadroder.color}
                    txt={cuadroder.txt}
                    info={cuadroder.info}
                    />
                  ))
                }

                {
                  this.state.CuadrosAbajo.map((cuadroder) => (
                    <Cuadros
                    color={cuadroder.color}
                    txt={cuadroder.txt}
                    info={cuadroder.info}
                    />
                  ))
                }
              </div>
            </div>
            </div>
          </div>
      </div>
    );
  }
}
